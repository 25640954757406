import { reducer as userReducer } from "./user";
import { reducer as appReducer } from "./app";
import { reducer as repositoryReducer } from "./repository";
import { reducer as fileReducer } from "./file";
import { reducer as migrationRepositoryReducer } from "./migration-repository";

export const reducer = {
  app: appReducer,
  user: userReducer,
  file: fileReducer,
  repository: repositoryReducer,
  migrationRepository: migrationRepositoryReducer
};
