import styled from "@emotion/styled";
import { Button } from "antd";
import { createContext, Dispatch, lazy, SetStateAction, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Message, RepoModel } from "@types";

import { ChatSvg, GraphInSvg, DatabaseInSvg, SysAssessmentSvg, SysExplorationSvg } from "@assets/svg";

export const ExportBtn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary100}80;

  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 6px;
    }
  }
`;

// const AssessmentPage = lazy(() => import("@pages/exploration/assessment"));
const InventoryPage = lazy(() => import("@pages/exploration/inventory"));
const ComplexityPage = lazy(() => import("@pages/exploration/complexity"));
const DataAssetPage = lazy(() => import("@pages/exploration/data-asset"));
const DependencyGraphPage = lazy(() => import("@pages/exploration/dependency"));
const ChatBotPage = lazy(() => import("@pages/exploration/chatbot"));
const TopbarLayout = lazy(() => import("@layouts/TopbarLayout"));

const MessageContext = createContext<{
  messages: Message[];
  setMessages: Dispatch<SetStateAction<Message[]>>;
}>(
  {} as {
    messages: Message[];
    setMessages: Dispatch<SetStateAction<Message[]>>;
  }
);

export const useMessages = () => {
  return useContext(MessageContext);
};

const ProjectContext = createContext<{ project: RepoModel }>({} as { project: RepoModel });

export const useProject = () => {
  return useContext(ProjectContext);
};

export const ExplorationRouter = () => {
  const navigate = useNavigate();
  const { projectId, explorationCategory } = useParams();
  const [messages, setMessages] = useState<Message[]>([]);

  const onChangeSegmented = (value: string) => {
    navigate(`/exploration/${projectId}/${value}`);
  };

  return (
    <TopbarLayout
      segmented={{
        options: [
          {
            label: "Assets Inventory",
            value: "assets",
            Icon: () => <SysAssessmentSvg className='icon' />,
            breadcrumb: "Dashboard"
          },
          {
            label: "Complexity Overview",
            value: "complexity",
            Icon: () => <SysExplorationSvg className='icon' />,
            breadcrumb: "Dashboard"
          },
          {
            label: "Data Asset Inventory",
            value: "data-asset",
            Icon: () => <DatabaseInSvg className='icon' />,
            breadcrumb: "List"
          },
          {
            label: "Mainframe Expert AI",
            value: "chatbot",
            Icon: () => <ChatSvg className='icon' />,
            breadcrumb: "Dashboard"
          },
          {
            label: "Dependency Graph",
            value: "graph",
            Icon: () => <GraphInSvg className='icon' />,
            breadcrumb: "List"
          }
        ],
        onChange: onChangeSegmented,
        defaultValue: "assets",
        value: explorationCategory
      }}
    >
      {explorationCategory === "assets" ? (
        <InventoryPage />
      ) : explorationCategory === "complexity" ? (
        <ComplexityPage />
      ) : explorationCategory === "chatbot" ? (
        <MessageContext.Provider value={{ messages, setMessages }}>
          <ChatBotPage />
        </MessageContext.Provider>
      ) : explorationCategory === "graph" ? (
        <DependencyGraphPage />
      ) : explorationCategory === "data-asset" ? (
        <DataAssetPage />
      ) : null}
    </TopbarLayout>
  );
};
