// > Workspace
export const workspace = {
  WORKSPACE: "/:workspaceCategory?",
  WORKSPACE_HOME: "/",
  WORKSPACE_REPOSITORY: "/repository",
  WORKSPACE_REPOSITORIES: "/repositories",
  WORKSPACE_EXPLORATION: "/exploration",
  WORKSPACE_MIGRATION_REPOSITORY: "/migration"
};

export const workspaceCategories: Record<
  keyof Pick<
    typeof workspace,
    | "WORKSPACE_REPOSITORY"
    | "WORKSPACE_EXPLORATION"
    | "WORKSPACE_MIGRATION_REPOSITORY"
    | "WORKSPACE_REPOSITORIES"
  >,
  string
> = {
  WORKSPACE_REPOSITORY: workspace.WORKSPACE_REPOSITORY.replace("/", ""),
  WORKSPACE_REPOSITORIES: workspace.WORKSPACE_REPOSITORIES.replace("/", ""),
  WORKSPACE_EXPLORATION: workspace.WORKSPACE_EXPLORATION.replace("/", ""),
  WORKSPACE_MIGRATION_REPOSITORY: workspace.WORKSPACE_MIGRATION_REPOSITORY.replace("/", "")
};

export const workspaceRouters: Record<keyof typeof workspace, string> = {} as any;
Object.keys(workspace).forEach(
  key => ((workspaceRouters as any)[key] = `/workspace${(workspace as any)[key]}`)
);

// > Exploration
export const exploration = {
  EXPLORATION: "/:explorationCategory?/*",
  EXPLORATION_HOME: "/",
  EXPLORATION_GRAPH: "/graph",
  EXPLORATION_FILE: "/file",
  EXPLORATION_ASSESSMENT: "/assessment",
  EXPLORATION_FILE_WITH_ID: "/file/:fileId",
  EXPLORATION_FILE_WITH_ID_CATEGORY: "/file/:fileId/:fileCategory?"
};

export const migration = {
  MIGRATION: "/:migrationCategory?/*",
  MIGRATION_HOME: "/",
  MIGRATION_GRAPH: "/graph",
  MIGRATION_FILE: "/file",
  MIGRATION_ASSESSMENT: "/assessment",
  MIGRATION_FILE_WITH_ID: "/file/:fileId",
  MIGRATION_FILE_WITH_ID_CATEGORY: "/file/:fileId/:fileCategory?",
  MIGRATION_FILE_MATCHING: "/file/:fileId/matching"
};

export const explorationCategories: Record<
  keyof Pick<
    typeof exploration,
    "EXPLORATION_GRAPH" | "EXPLORATION_FILE" | "EXPLORATION_ASSESSMENT"
  >,
  string
> = {
  EXPLORATION_GRAPH: exploration.EXPLORATION_GRAPH.replace("/", ""),
  EXPLORATION_FILE: exploration.EXPLORATION_FILE.replace("/", ""),
  EXPLORATION_ASSESSMENT: exploration.EXPLORATION_ASSESSMENT.replace("/", "")
};

export const explorationRouters: Record<keyof typeof exploration, string> = {} as any;
Object.keys(exploration).forEach(
  key => ((explorationRouters as any)[key] = `/exploration${(exploration as any)[key]}`)
);

export const migrationRouters: Record<keyof typeof migration, string> = {} as any;
Object.keys(migration).forEach(
  key =>
    ((migrationRouters as any)[key] = `${workspace.WORKSPACE_MIGRATION_REPOSITORY}${
      (migration as any)[key]
    }`)
);

const mainRouters = {
  LOGIN: "/signin",
  REGISTER: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY: "/verify",
  RESET_PASSWORD: "/resetpwd",
  UNAUTHORIZED: "/401",
  NOT_FOUND: "/404",
  SERVER_ERROR: "/500",
  COMMING_SOON: "/coming-soon",
  UNDER_MAINTENANCE: "/under-maintenance",
  PRIVACY_POLICY: "/privacy-and-policy",
  THEME: "/theme",
  SHARE: "/share/:projectId",
  SHARE_MIGRATION: "/share/:projectId?matchingId=:matchingId"
};

export const ROUTERS = {
  ...mainRouters,
  ...workspaceRouters,
  ...explorationRouters,
  ...migrationRouters
};

export const ROUTERS_GUARD = [ROUTERS.WORKSPACE, ROUTERS.EXPLORATION];
