import styled from "@emotion/styled";
import { Empty } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverflowEmpty: React.FC = () => {
  return (
    <Wrapper>
      <Empty />
    </Wrapper>
  );
};
