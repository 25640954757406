import { configureStore } from "@reduxjs/toolkit";

import { reducer } from "./reducer";

// > Config
export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
});

// > Logger
// eslint-disable-next-line
// store.subscribe(() => console.log(store.getState()))

type StoreState = ReturnType<typeof store.getState>;
type StoreDispatch = typeof store.dispatch;

declare global {
  type RootState = StoreState;
  type AppDispatch = StoreDispatch;
}
