import "./lang";
import React from "react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Global as GlobalStyle, ThemeProvider } from "@emotion/react";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip
} from "chart.js";

import { store } from "@store";
import { antdTheme, globalStyle, theme } from "@style";
Chart.register([
  ArcElement,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip
]);

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <GlobalStyle styles={globalStyle} />
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={antdTheme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ConfigProvider>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
