import styled from "@emotion/styled";
import { Button } from "antd";
import { createContext, lazy, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { repositoryApi } from "@api";
import { Flex } from "@components";
import { RepoModel } from "@types";
import { SysAssessmentSvg, SysExplorationSvg } from "@assets/svg";

export const ExportBtn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary100}80;

  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 6px;
    }
  }
`;

// const AssessmentPage = lazy(() => import("@pages/exploration/assessment"));
const InventoryPage = lazy(() => import("@pages/exploration/inventory"));
const ComplexityPage = lazy(() => import("@pages/exploration/complexity"));
const TopbarLayout = lazy(() => import("@layouts/TopbarLayout"));

const ProjectContext = createContext<{ project: RepoModel }>({} as { project: RepoModel });

export const useProject = () => {
  return useContext(ProjectContext);
};

export const ExplorationRouter = () => {
  const navigate = useNavigate();
  const { projectId, explorationCategory } = useParams();
  const [project, setProject] = useState<RepoModel>();

  const onChangeSegmented = (value: string) => {
    navigate(`/exploration/${projectId}/${value}`);
  };

  useEffect(() => {
    const fetchProject = async () => {
      if (!projectId) return;
      const response = await repositoryApi.getRepositoryRequest(projectId);

      if (!response?.data) return;
      setProject(response.data);
    };

    fetchProject();
  }, [projectId]);

  return (
    <TopbarLayout
      segmented={{
        options: [
          {
            label: "Assets Inventory",
            value: "assets",
            Icon: () => <SysAssessmentSvg className='icon' />,
            breadcrumb: "Dashboard"
          },
          {
            label: "Complexity Overview",
            value: "complexity",
            Icon: () => <SysExplorationSvg className='icon' />,
            breadcrumb: "Dashboard"
          }
        ],
        onChange: onChangeSegmented,
        defaultValue: "assets",
        value: explorationCategory
      }}
      // onClickBack={onClickBack}
      // progress={progressPercent === 100 ? undefined : { percent: progressPercent }}
    >
      {project ? (
        <ProjectContext.Provider value={{ project }}>
          <Flex gap={12} style={{ position: "absolute", right: 40, top: 28 }}>
            {/* <ExportBtn type='primary' onClick={exportAssessment} disabled={downloadAssessment}>
              {downloadAssessment ? (
                <div>
                  {t("button.running")} ... <LoadingOutlined />
                </div>
              ) : (
                <div>
                  <span>{t("button.export_assessment")}</span> <ArrowRightSvg className='icon' />
                </div>
              )}
            </ExportBtn>
            <ExportBtn type='primary' onClick={exportDeadcode} disabled={downloadDeadcode}>
              {downloadDeadcode ? (
                <div>
                  {t("button.running")} ... <LoadingOutlined />
                </div>
              ) : (
                <div>
                  <span>{t("button.export_deadcode")}</span> <ArrowRightSvg className='icon' />
                </div>
              )}
            </ExportBtn>
            <ExportBtn type='primary' onClick={exportAllReport} disabled={downloadExport}>
              {downloadExport ? (
                <div>
                  {t("button.running")} ... <LoadingOutlined />
                </div>
              ) : (
                <div>
                  <span>{t("button.export_all")}</span> <ArrowRightSvg className='icon' />
                </div>
              )}
            </ExportBtn> */}
          </Flex>
          {explorationCategory === "assets" ? (
            <InventoryPage project={project} />
          ) : explorationCategory === "complexity" ? (
            <ComplexityPage project={project} />
          ) : explorationCategory === "dataset" ? null : null}
          {/* {explorationCategory === explorationCategories.EXPLORATION_ASSESSMENT ? (
            <AssessmentPage />
          ) : explorationCategory === explorationCategories.EXPLORATION_FILE ? (
            <FilePage />
          ) : (
            <GraphPage />
          )} */}
        </ProjectContext.Provider>
      ) : null}
    </TopbarLayout>
  );
};
