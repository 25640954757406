import { useState } from "react";
import { useDispatch } from "react-redux";

import { api } from "@api";
import { appActions, userActions } from "@store";

export const useLogout = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const cleanStore = () => {
    dispatch(userActions.clean());
    dispatch(appActions.clean());
  };

  const logout = async () => {
    try {
      setLoading(true);

      api.removeToken();

      cleanStore();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return { logout, loading };
};
