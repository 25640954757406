import { DEFAULT_QUERIES } from "@defines";
import { PaginationQueries, PaginationSortItem } from "@types";

export const queriesToString = (options?: {
  defaultQueries?: boolean;
  currentQueries?: PaginationQueries;
}) => {
  const search = new URLSearchParams();
  const queries =
    options?.defaultQueries || !options?.currentQueries ? DEFAULT_QUERIES : options.currentQueries;
  Object.keys(queries).forEach(o => {
    const value = (queries as any)[o];
    if (o === "sort") {
      if (typeof value === "object" && value.length) {
        const sortItems = value as PaginationSortItem[];
        const sortString = [...sortItems].map(oo => `${oo.field},${oo.sort}`).join("|");
        search.append("sort", sortString);
      }
    } else if (o === "keyword") {
      if (value) search.append("keyword", value.trim().toLowerCase());
    } else if (o === "page") {
      search.append("page", value + 1);
    } else search.append(o, value);
  });
  return search;
};

export function generateUUID() {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export const setFullScreen = (params?: { fullScreen?: boolean; elementId?: string }) => {
  const { elementId, fullScreen } = params || {};
  const elem: any = elementId ? document.getElementById(elementId) : document.documentElement;
  if (!elem) return;

  if (fullScreen !== false) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  } else if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
      /* Safari */
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      /* IE11 */
      (document as any).msExitFullscreen();
    }
  }
};

export const roundToDecimalPlaces = (num: number, decimalPlaces: number) => {
  if (!num || !decimalPlaces) {
    return 0;
  }
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

export const handleDownloadFile = (data: Blob, fileName?: string) => {
  const link = document.createElement("a");

  // Create a Blob URL for the downloaded file
  const blobUrl = URL.createObjectURL(data);

  // Set the link's href attribute to the Blob URL
  link.href = blobUrl;

  // Specify the suggested name for the file
  link.download = fileName || "downloaded";

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
