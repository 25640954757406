import { createContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ROUTERS } from "@defines";

import { authApi } from "../api/auth";

const UserContext = createContext({});

export const UserProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getUser = async () => {
      const response = await authApi.verifyToken();

      if (response?.data) return;
      const search = new URLSearchParams(`redirect=${location.pathname}`);
      const queries = search.toString();

      navigate(`${ROUTERS.LOGIN}${queries ? `?${queries}` : ""}`);
    };

    getUser();
  }, []);
  return (
    <UserContext.Provider value={{}}>
      <Outlet />
    </UserContext.Provider>
  );
};
