import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { api, userApi } from "@api";
import { storage } from "@defines";
import { useController } from "@hooks";
import { userActions } from "@store";
import { LoginParams } from "@types";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { controller } = useController();
  const navigate = useNavigate();

  const handleLogin = async (params: LoginParams) => {
    controller(
      async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("username", params?.username);
        formData.append("password", params?.password);
        const res: any = await userApi.doLoginRequest(formData);
        api.setToken(res?.access_token);
        localStorage.setItem(storage.TOKEN, String(res?.access_token));
        dispatch(
          userActions.setUser({
            id: "1",
            email: "fpt@fpt.com",
            username: "fpt",
            firstName: "A",
            lastName: "Nguyen Van",
            isVerified: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            status: "active"
          })
        );
        const search = new URLSearchParams(location.search);
        const redirect = search.get("redirect");

        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/projects");
        }
      },
      {
        onDone: () => setLoading(false),
        onError: (error: any) => {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    );
  };

  return { handleLogin, loading };
};
